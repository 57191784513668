













































































































































































































































































































































































































































































































































.addAndEditData {
  .el-form-item {
    .el-form-item__content {
      .el-input-number {
        width: 100%;
      }
    }
  }
}
